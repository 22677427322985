import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';
import moment from 'moment-timezone';

export default new class ManipulateDate {

    parse(data, session) {
        const { value, year, month,
            date, hour, minute, second, timezone } = data.data;

        const parsedValue = (VariableParser.parse(FP.parse(value, session), session))
            .replace(/(<([^>]+)>)/gi, '').trim();
        const parsedMoment = moment.tz(parsedValue, timezone);

        parsedMoment.set({
            year: parseInt(year || parsedMoment.year()),
            month: parseInt(month || parsedMoment.month()),
            date: parseInt(date || parsedMoment.date()) + 1,
            hour: parseInt(hour || parsedMoment.hour()),
            minute: parseInt(minute || parsedMoment.minute()),
            second: parseInt(second || parsedMoment.second())
        });

        return parsedMoment.format();
    }
};
