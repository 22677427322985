import { parseExpression } from './ExpressionParser';

export const jsonBuilder = {
    buildObject(structuredObject, properties, values) {

        for (const property of properties) {
            if (property.type === 'Number') {
                let numberProp = parseExpression(property.value, values);
                if (typeof numberProp !== 'undefined' && numberProp !== '<empty>') {
                    numberProp = parseFloat(numberProp.replace(/<[^>]*>?/gm, ''));
                    structuredObject[property.name] = numberProp;
                }
            }

            if (property.type === 'String') {
                let stringProp = parseExpression(property.value, values);

                if (typeof stringProp !== 'undefined' && stringProp !== '<empty>') {
                    stringProp = stringProp.replace(/<[^>]*>?/gm, '').trim();
                    structuredObject[property.name] = stringProp;
                }
            }

            if (property.type === 'Object') {
                if (!structuredObject[property.name]) {
                    structuredObject[property.name] = {};
                }
                structuredObject[property.name] = this.buildObject(structuredObject[property.name], property.children, values);
            }

            if (property.type === 'Array') {
                if (!Array.isArray(structuredObject[property.name])) {
                    structuredObject[property.name] = [];
                }
                if (property.dynamic) {
                    let value = parseExpression(property.value, values);
                    value = value.replace(/<[^>]*>?/gm, '').trim();
                    try {
                        JSON.parse(value);
                    } catch (e) {
                        value = `[${value.substring(1, value.length - 1)}]`;
                    }

                    structuredObject[property.name].push(JSON.parse(value));
                } else {
                    structuredObject[property.name] = this.buildArray(structuredObject[property.name], property.value, values);
                }
            }
        }

        return structuredObject;
    },
    buildArray(propArray, value, values) {
        for (const v of value) {
            if (v.type === 'Number') {
                let numberProp = parseExpression(v.value, values);
                numberProp = parseFloat(numberProp.replace(/<[^>]*>?/gm, ''));
                propArray.push(numberProp);
            }

            if (v.type === 'String') {
                let stringProp = parseExpression(v.value, values);
                stringProp = stringProp.replace(/<[^>]*>?/gm, '').trim();
                propArray.push(stringProp);
            }

            if (v.type === 'Object') {
                propArray.push(this.buildObject({}, v.children, value));
            }
        }
        return propArray;
    }
};
