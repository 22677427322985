import VariableParser from '@/helpers/VariableParser';
import axios from 'axios';

export default new class Base64Encoder {
    parse(data, values) {
        return axios.get(VariableParser.parse(data.data.url.replace(/(<([^>]+)>)/gi, '').trim(), values), {responseType: 'arraybuffer'}).then(response => {
            return Buffer.from(response.data).toString('base64');
        });
    }
};
