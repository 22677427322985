// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { INTERPRETER_MODULE_RESOURCE_PATH, INTERPRETER_SERVICE_ENDPOINT, INTERPRETER_MODULE_RUN_RESOURCE_PATH, INTERPRETER_MODULE_PARSE_RESOURCE_PATH, INTERPRETER_MODULE_CHECK_RESOURCE_PATH } from './interpreterServiceConstants';

const interpreterModuleServiceConfig = {
    ...createHttpServiceConfig(INTERPRETER_SERVICE_ENDPOINT, INTERPRETER_MODULE_RESOURCE_PATH),
    /**
     * @param {string} moduleId
     */
    getModuleRunResourcePath: (moduleId) => `/${moduleId}${INTERPRETER_MODULE_RUN_RESOURCE_PATH}`,
    /**
     * @param {string} moduleId
     */
    getModuleParseResourcePath: (moduleId) => `/${moduleId}${INTERPRETER_MODULE_PARSE_RESOURCE_PATH}`,
    /**
     * @param {string} moduleId
     */
    getModuleCheckResourcePath: (moduleId) => `/${moduleId}${INTERPRETER_MODULE_CHECK_RESOURCE_PATH}`,
};

//-- request interceptors --//
interpreterModuleServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} moduleId
 * @param query
 * @param {import('../../types/interpreterTypes').IRunModule} data
 * @param {object} [params]
 */
export const runModuleService = (moduleId, query, data, params) => interpreterModuleServiceConfig.http({
    method: 'POST',
    url: `${interpreterModuleServiceConfig.getModuleRunResourcePath(moduleId)}${query}`,
    params,
    data
});

/**
 * @param {string} moduleId
 * @param {object} data
 */
export const parseModuleService = (moduleId, data) => interpreterModuleServiceConfig.http({
    method: 'POST',
    url: interpreterModuleServiceConfig.getModuleParseResourcePath(moduleId),
    data
});

/**
 * @param {string} moduleId
 * @param {import('../../types/interpreterTypes').ICheckModule} data
 */
export const checkModuleService = (moduleId, data) => interpreterModuleServiceConfig.http({
    method: 'POST',
    url: interpreterModuleServiceConfig.getModuleCheckResourcePath(moduleId),
    data
});
