import * as cheerio from 'cheerio';

export default new class VariableParser {
    parse(text, values) {
        const htmlDoc = cheerio.load(text || '');
        const body = htmlDoc.root().find('body');
        const elements = body.find('.mention');
        for (let element of elements) {
            let variable_id = cheerio.default(element).data('mention-id');
            let value = '';
            if (values && values[variable_id]) {
                value = values[variable_id];
            }
            text = text.replace(cheerio.default(element).wrap('<span>').parent().html(), value);
            text = text.trim();
        }
        return text;
    }
};
