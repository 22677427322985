import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';

export default new class  Lower {

    parse(data, values) {
        const text = FP.parse(data.data.value, values);
        return VariableParser.parse(data.data.value, values)
            .replace(/(<([^>]+)>)/gi, '').trim().toLowerCase();
    }
};

