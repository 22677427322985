import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';
const FormulaParser = require('hot-formula-parser').Parser;
const formula_parser = new FormulaParser();

export default new class  MultipleCondition {

    parse(data, values) {
        const switchText = FP.parse(data.data.value, values);
        let switchValue = VariableParser.parse(switchText, values).replace(/(<([^>]+)>)/gi, '').trim();
        for (const option of data.data.options) {
            const optionText = FP.parse(option.case, values);
            const optionValue = FP.parse(option.value, values);
            if (switchValue == VariableParser.parse(optionText, values).replace(/(<([^>]+)>)/gi, '').trim()) {
                return VariableParser.parse(optionValue, values).replace(/(<([^>]+)>)/gi, '').trim();
            }
        }
        if (data.data.hasDefault) {
            const defaultText = FP.parse(data.data.defaultValue);
            return VariableParser.parse(defaultText, values).replace(/(<([^>]+)>)/gi, '').trim();
        }

        return '';
    }
};

