import VariableParser from '@/helpers/VariableParser';
const moment = require('moment-timezone');
import FP from './../FormulaParser';

export default new class  Now {

    parse(data, values) {
        if (data.data.format === 'Custom') {
            const text = FP.parse(data.data.customFormat, values);
            return moment.tz(data.data.timezone).format(
                VariableParser.parse(text, values)
                    .replace(/(<([^>]+)>)/gi, '').trim()
            );
        }
        return moment().tz(data.data.timezone).format(data.data.format);
    }
};
