import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';

export default new class  Upper {

    parse(data, values) {
        const text = FP.parse(data.data.value, values);
        return VariableParser.parse(text, values)
            .replace(/(<([^>]+)>)/gi, '').trim().toUpperCase();
    }
};

