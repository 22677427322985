import VariableParser from '@/helpers/VariableParser';
const {default: $} = require('cheerio');
import FP from './../FormulaParser';

export default new class  Left {

    parse(data, values) {
        const text = FP.parse(data.data.str.value);
        return VariableParser.parse(text, values).replace(/(<([^>]+)>)/gi, '').trim()
            .substring(0, parseInt(data.data.str.numOfCharacters));
    }
};

