import * as cheerio from 'cheerio';
import FormulaParser from './FormulaParser';
import VariableParser from './VariableParser';

/**
 * Parses an expression and returns text with computed values
 * @param {string} rawExpression 
 * @param {object} values 
 * @param {boolean} [clearHtmlElements]
 */
export const parseExpression = (rawExpression, values, clearHtmlElements = true) => {
    if (!rawExpression) {
        return rawExpression;
    }
    const parsedVariables = VariableParser.parse(rawExpression, values);
    const parsedText = FormulaParser.parse(parsedVariables, values);
    return clearHtmlElements ? removeHTMLElements(parsedText) : parsedText;
};

export const removeHTMLElements = (parsedExpression) => {
    const formattedText = parsedExpression.replace(/<[^>]*>?/gm, '').trim();
    return formattedText;
};

/**
  * Parses formulas inside specified attribute of an HTML element 
  * @param {object} Tag
  * @param {string} raw_value
  * @param {object} values
*/
export const parseTagAttribute = (Tag, raw_value, values) => {
    if (raw_value) {
        const htmlDoc = cheerio.load(raw_value);
        const body = htmlDoc.root().find('body');
        const elements = body.find(`${Tag.selector}[${Tag.attribute}]`);
        for (const element of elements) {
            if (element.attribs[Tag.attribute] && element.attribs[Tag.attribute].startsWith('<p>')) {
                element.attribs[Tag.attribute] = parseExpression(element.attribs[Tag.attribute], values);
            }
        }
        return body.html();
    }
    return raw_value;
};

/**
 * Extracts reference id of a variable from raw value
 * @param {string} raw_value 
 */
export const extractReferenceId = (raw_value) => {
    if (raw_value) {
        const htmlDoc = cheerio.load(raw_value);
        const body = htmlDoc.root().find('body');
        const elements = body.find('.mention');
        const referenceId = elements ? htmlDoc(elements[0]).data('mention-id') : null;
        return referenceId;
    }
    return null;
};
