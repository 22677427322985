import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';

export default new class NumberFormatter {

    parse(data, values) {
        const number = FP.parse(data.data.number, values);
        const parsedNumber = VariableParser.parse(number, values)
            .replace(/(<([^>]+)>)/gi, '').trim();
        const decimalPlaces =  VariableParser.parse(data.data.decimalPlaces, values)
            .replace(/(<([^>]+)>)/gi, '').trim();

        let format = 'de-DE';
        switch (data.data.format) {
        case 'United States':
            format = 'en-US';
            break;
        }

        const formatter = new Intl.NumberFormat(format, {
            style: data.data.currency.show ? 'currency' : 'decimal',
            currency: data.data.currency.type,
            minimumFractionDigits: parseInt(decimalPlaces),
            maximumFractionDigits: parseInt(decimalPlaces),
        });

        return formatter.format(parsedNumber);
    }
};

