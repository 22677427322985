import { parseExpression } from '@/helpers/ExpressionParser';

export default new class AccessProperty {
    parse(data, values) {
        const { objectReference, accessPath } = data.data;

        const value = parseExpression(objectReference, values);
        const parsedObject = this.parseObject(value);

        const path = parseExpression(accessPath, values);

        let processedValue = '';
        if (parsedObject) {
            const accessedValue = path.split('.').reduce((result, key) => {
                result = result ? result[key] : parsedObject[key];
                return result;
            }, null);
            if (['string', 'boolean', 'number'].includes(typeof accessedValue)) {
                processedValue = accessedValue;
            } else {
                processedValue = JSON.stringify(accessedValue);
                if (Array.isArray(accessedValue)) {
                    processedValue = `{${processedValue.substring(1, processedValue.length - 1)}}`;
                }
            }
        }
        return processedValue;
    }

    parseObject (data) {
        try {
            // handle parsing object
            const objectSymbols = ['{', '['];
            if (data && !objectSymbols.includes(data.charAt(0)) && !objectSymbols.includes(data.charAt(data.length - 1))) {
                // value is primitive, convert to array
                data = `["${data}"]`;
            }
            const parsedObject = JSON.parse(data);
            return parsedObject;
        } catch (err) {
            // otherwise, JSON is not valid, handle parsing arrays
            let parsedObject = null;
            if (data.charAt(0) === '{' && data.charAt(data.length - 1) === '}') {
                data = `[${data.substring(1, data.length - 1)}]`;
                parsedObject = JSON.parse(data);
            }
            return parsedObject;
        }
    }
};
