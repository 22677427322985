import FormulaParser from '@/helpers/FormulaParser';
import VariableParser from '@/helpers/VariableParser';
const filtrex = require('filtrex');

export default new class ConditionChecker {
    isConditionSatisfied(condition, values) {
        if (!condition || !condition.children || !condition.children.length) {
            return true;
        }

        if (condition.children[0].query.rule === 'always') {
            return true;
        }

        const operator = condition.logicalOperator === 'all' ? ' and ' : ' or ';
        const expression = this.parseQueryToExpression(condition.children, operator, values);
        console.log(expression);
        const evaluator = filtrex.compileExpression(expression);
        return evaluator({});
    }

    parseQueryToExpression(rules, operator, values) {
        let expression = '';
        for (let i = 0; i < rules.length; i++) {
            if (rules[i].type === 'query-builder-rule') {
                let variable = values[rules[i].query.rule];
                let value = this.parseValue(rules[i], values);
                expression += this.parseQueryOperation(variable, rules[i].query.operator, value, operator);
            }
        }
        return expression.slice(0, -4).trim();
    }

    parseValue(rule, values) {
        let value = rule.query?.value ? VariableParser.parse(FormulaParser.parse(rule.query.value, values), values) : '';
        if (value) {
            value = value.replace(/(<([^>]+)>)/gi, '');
        }
        if (value === 'null') {
            value = '';
        }
        return value;
    }

    parseArray(rawArrayValue){
        if(!rawArrayValue) return [];
        let values = [];
        if (
            rawArrayValue?.startsWith('[') && rawArrayValue?.endsWith(']') 
            ||
            rawArrayValue?.startsWith('{') && rawArrayValue?.endsWith('}')
        ) {
            values = rawArrayValue.substring(1, rawArrayValue.length - 1)?.split(',')?.map(item => {
                return item.replace(/["\\]/g, ''); // Removes " and \ from each item
            }) || [];
        }else{
            values = [rawArrayValue];
        }
        return values;
    }

    parseQueryOperation(variable, operation, value, operator) {
        let arrVal;
        switch (operation) {
        case 'equals':
            return '"' + variable + '"' + ' == ' + '"' + value + '" ' + operator;
        case 'does not equal':
            return '"' + variable + '"' + ' != ' + '"' + value + '" ' + operator;
        case 'less than':
            return parseFloat(variable) + ' < ' + parseFloat(value) + operator;
        case 'more than':
            return parseFloat(variable) + ' > ' + parseFloat(value) + operator;
        case 'less than or equal to':
            return parseFloat(variable) + ' <= ' + parseFloat(value) + operator;
        case 'more than or equal to':
            return parseFloat(variable) + ' >= ' + parseFloat(value) + operator;
        case 'empty':
            return '"' + variable + '"' + ' == ' + '""' + operator;
        case 'not empty':
            return '"' + variable + '"' + ' != ' + '""' + operator;
        case 'contains':
            if (variable?.includes(value)) {
                return '1 == 1' + operator;
            }
            return '1 != 1' + operator;
        case 'does not contain':
            if (!variable?.includes(value)) {
                return '1 == 1' + operator;
            }
            return '1 != 1 ' + operator;
        case 'begins with':
            if (variable?.substring(0, value.length) === value) {
                return '1 == 1' + operator;
            }
            return '1 != 1 ' + operator;
        case 'ends with':
            if (variable?.substring(variable.length - value.length) === value) {
                return '1 == 1' + operator;
            }
            return '1 != 1 ' + operator;
        case 'is part of':
            arrVal = this.parseArray(value) || [];
            return Array.isArray(arrVal) && arrVal.includes(variable)
                ? `1 == 1 ${operator}`
                : `1 != 1 ${operator}`;
        case 'is not part of':
            arrVal = this.parseArray(value) || [];
            return Array.isArray(arrVal) && !arrVal.includes(variable)
                ? `1 == 1 ${operator}`
                : `1 != 1 ${operator}`;
        }
    }
};