import { parseExpression } from '../ExpressionParser';

export default new class SplitString {
    extractWithRegex (inputString, pattern){
        const regex = new RegExp(pattern);
        const match = inputString?.match(regex);
        if (match) {
            const matchingPart = match[0];
            const nonMatchingParts = inputString?.split(matchingPart);
            return [matchingPart?.trim(), nonMatchingParts?.join('')?.trim()];
        }
        return ['', inputString];
    }
    parse(data, values){
        const { isDelimiterRegex } = data.data;
        const inputString = parseExpression(data.data.stringReference, values);
        const regex = parseExpression(data.data.regex, values);
        const delimeter = parseExpression(data.data.delimeter, values);
        let resultArr = [];

        if(isDelimiterRegex){
            resultArr = this.extractWithRegex(inputString, regex);
        }else{
            resultArr = inputString?.split(delimeter);
        }

        const processedValue = JSON.stringify(resultArr);
        return `{${processedValue.substring(1, processedValue.length - 1)}}`;
    }
};