import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';

export default new class  Right {

    parse(data, values) {
        const text = FP.parse(data.data.str.value, values);
        return VariableParser.parse(text, values)
            .replace(/(<([^>]+)>)/gi, '').trim()
            .slice(0 - parseInt(data.data.str.numOfCharacters));
    }
};

