import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';
import { parseExpression } from '../ExpressionParser';

export default new class  SortArray {

    genericComparator(a, b, isAscending){
        return isAscending ? (a > b ? 1 : a < b ? -1 : 0) : (b > a ? 1 : b < a ? -1 : 0);
    }


    sortArray(inputArray, isAscending, property) {
        if (!Array.isArray(inputArray)) {
            return inputArray;
        }

        const newArray = [...inputArray];

        newArray.sort((a, b) => {
            const aValue = property ? this.getPropertyValue(a, property) : a;
            const bValue = property ? this.getPropertyValue(b, property) : b;

            return this.genericComparator(aValue, bValue, isAscending);
        });

        return newArray;
    }

    getPropertyValue(obj, path) {
        const properties = path.split('.');
        return properties.reduce((acc, current) => acc[current], obj);
    }
    
    parse(data, values) {
        const { isAsc, sortByProperty,  arrayReference, accessPath } = data.data;

        let rawArrayValue = parseExpression(arrayReference, values);
        if (rawArrayValue.charAt(0) !== '[' && rawArrayValue.charAt(rawArrayValue.length - 1) !== ']') {
            if (rawArrayValue.charAt(0) === '{' && rawArrayValue.charAt(rawArrayValue.length - 1) === '}') {
                rawArrayValue = `[${rawArrayValue.substring(1, rawArrayValue.length - 1)}]`;
            } else {
                rawArrayValue = `["${rawArrayValue}"]`;
            }
        }

        const parsedArray = JSON.parse(rawArrayValue);
        const property = parseExpression(accessPath, values);
        const sortedArray = this.sortArray(parsedArray , isAsc ,sortByProperty ? property : null);
        const processedValue = JSON.stringify(sortedArray);
        return `{${processedValue.substring(1, processedValue.length - 1)}}`;
    }
};

