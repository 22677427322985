import ConditionChecker from '@/helpers/ConditionChecker';
import { parseExpression, parseTagAttribute } from './ExpressionParser';

export default new class FormRenderer {
    check(data, values) {
        for (let section of data.sections) {
            section.show = !!ConditionChecker.isConditionSatisfied(section.condition, values);
            for (const column of section.fields) {
                for (const field of column) {
                    this.renderForm(field, values);
                }
            }
        }
        return data;
    }

    /**
     * Parses the raw values of a field and update its values with parsed value
     * @param {object} field 
     * @param {object} values Contains parsed values of all fields
     */
    renderForm(field, values) {
        field.show = !!ConditionChecker.isConditionSatisfied(field.condition, values);
        const fieldBasicProperties = field?.properties?.basic;

        if (!fieldBasicProperties) {
            return;
        }

        if (field.type === 'text' || field.type === 'toc') {
            fieldBasicProperties.text = parseExpression(fieldBasicProperties.rawText, values, false);
            if (fieldBasicProperties.text) {
                fieldBasicProperties.text = fieldBasicProperties.text
                    .replaceAll('{', '')
                    .replaceAll('}', '')
                    .replaceAll('"', '');
            }
            const images = fieldBasicProperties.text.match(/(\bdata:image\/png;base64\S+\S+)/ig);
            if (images?.length) {
                images.forEach(image => {
                    fieldBasicProperties.text = fieldBasicProperties.text.replace(image, `<img src="${image}" class="var-img" width="340" height="150"  alt=""/>`);
                });
            }

        } else if (field.type === 'data-table') {

            let data = [];

            fieldBasicProperties.headers = [];
            fieldBasicProperties.data = [];

            for (const row of fieldBasicProperties.columns) {
                fieldBasicProperties.headers.push({
                    field: row.label,
                    label: row.label,
                    visible: row.visible
                });

                data.push({
                    label: row.label, value: parseExpression(row.value, values)
                });
            }

            let orderedData = [];
            data.forEach((row, index) => {
                let singleRow = {};
                row.value = row.value.replace(/<[^>]*>?/gm, '').trim();
                row.value = row.value.replace('{', '');
                row.value = row.value.replace('}', '');
                row.value = row.value.replaceAll('"', '');

                values = row.value.split(',');
                values.forEach((value, i) => {
                    if (typeof singleRow[i] === 'undefined') {
                        singleRow[i] = {};
                    }
                    singleRow[i][row.label] = value;
                });
                orderedData.push(singleRow);
            });

            let groupedData = [];

            for (let i = 0; i < orderedData.length; i++) {
                for (const order in orderedData[i]) {
                    if (typeof groupedData[order] === 'undefined') {
                        groupedData[order] = [];
                    }
                    groupedData[order].push(orderedData[i][order]);
                }
            }

            groupedData.forEach(d => {
                fieldBasicProperties.data.push(d.reduce(((r, c) => Object.assign(r, c)), {}));
            });
        } else if (field.type === 'e-signature') {
            field.value = values[field.id];
        } else if (field.type === 'payment-gateway') {
            const { payload, serviceProvider } = fieldBasicProperties;
            if (serviceProvider === 'Element') {
                if (payload && Object.keys(payload).length) {
                    fieldBasicProperties.payload = {
                        ...payload,
                        quoteId: payload.quoteId ? parseExpression(payload.quoteId, values) : '',
                        signedUrl: payload.signedUrl ? parseExpression(payload.signedUrl, values) : '',
                        environment: payload.environment ? parseExpression(payload.environment, values) : ''
                    };
                }
            }
        } else if (field.type === 'tariff-comparison') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldExpressions?.highlightedTariffText) {
                fieldBasicProperties.highlightedTariffText = parseExpression(fieldExpressions.highlightedTariffText, values);
            }
            if (fieldBasicProperties?.tariffs) {
                fieldBasicProperties.tariffs.forEach(tariff => {
                    const tariffExpressions = tariff.expressions;
                    if (tariff.isVisible === undefined) {
                        tariff.isVisible = true;
                    } else if (tariff?.isVisibilityDynamic && tariffExpressions?.isVisible) {
                        tariff.isVisible = parseExpression(tariffExpressions.isVisible, values);
                    }
                    if (tariffExpressions?.isHighlighted) {
                        tariff.isHighlighted = parseExpression(tariffExpressions.isHighlighted, values);
                    }
                    if (tariffExpressions?.title && tariff?.title) {
                        tariff.title = parseExpression(tariffExpressions.title, values);
                    }
                    if (tariffExpressions?.price && tariff?.price) {
                        tariff.price = parseExpression(tariffExpressions.price, values);
                    }
                    if (tariffExpressions?.description && tariff?.description) {
                        const parsedLinkText = this.parseDynamicLinks(tariffExpressions.description, values);
                        tariff.description = parseExpression(parsedLinkText, values, false);
                    }
                    if (tariff.isButtonVisible === undefined) {
                        tariff.isButtonVisible = true;
                    } else if (tariff?.isButtonVisibilityDynamic && tariffExpressions?.isButtonVisible) {
                        tariff.isButtonVisible = parseExpression(tariffExpressions.isButtonVisible, values);
                    }
                });
            }
        }
    }

    parseDynamicLinks (rawValue, values) {
        const linkTag = {
            selector: 'a',
            attribute: 'href'
        };
        const parsedText = parseTagAttribute(linkTag, rawValue, values);
        return parsedText;
    }
};
