<template>
  <div class="preview">
    <div
      class="navbar mb-5"
      style="border-bottom: 1px solid #dedede"
    >
      <div class="navbar-brand column column py-0 is-2 is-12-mobile is-4-tablet is-3-desktop is-2-fullhd">
        <b-button
          size="is-small"
          class="mt-2 mr-2"
          type="is-primary"
          :href="'/application/edit/' + $route.params.moduleId"
          @click="backToBuilder()"
        >
          Back to the builder
        </b-button>
        <b-button
          size="is-small"
          class="mt-2 mr-2"
        >
          <b-icon
            size="is-small"
            icon="monitor"
            @click="showMobile(false)"
          />
        </b-button>
        <b-button
          size="is-small"
          class="mt-2"
        >
          <b-icon
            size="is-small"
            icon="cellphone"
            @click="showMobile(true)"
          />
        </b-button>
      </div>
      <div class="navbar-center column is-2 py-0 mx-auto">
        <b-notification
          class="mt-2 p-1 has-text-centered"
          type="is-primary"
          :closable="false"
        >
          Preview mode
        </b-notification>
      </div>
      <div class="navbar-end">
        <span class="mr-2 mt-3">Theme: </span>
        <b-select
          v-model="theme"
          size="is-small"
          class="mt-2"
          style="min-width: 200px;"
        >
          <option
            v-for="(themeOption, index) in ['Default', 'Purple', 'Dark Blue']"
            :key="index"
          >
            {{ themeOption }}
          </option>
        </b-select>
      </div>
    </div>

    <div class="preview-web">
      <div class="preview-frame box">
        <div class="preview-frame-header">
          <div class="preview-frame-arrows">
            <b-icon
              icon="arrow-left"
              size="is-small"
              type="is-black is-light"
            />
            <b-icon
              icon="arrow-right"
              size="is-small"
            />
          </div>
          <div class="preview-frame-address-bar">
            <span>https://preview.nuclicore.com/your-module</span>
          </div>
          <div class="preview-frame-buttons">
            <div />
            <div />
            <div />
          </div>
        </div>
        <div
          v-if="layout.header && layout.header.show"
          class="app-header"
          style="margin-top: 50px;"
        >
          <b-navbar>
            <template #brand>
              <b-navbar-item
                tag="router-link"
                to="#"
              >
                <img
                  v-if="layout.header.logo.show"
                  :src="layout.logo"
                  class="main-logo"
                  alt="Logo"
                  :class="layout.header.logo.position === 'left' ? 'is-pulled-left' : 'is-pulled-right'"
                >
              </b-navbar-item>
            </template>
            <template
              v-if="layout.header.navigation.show"
              #end
            >
              <b-navbar-item
                v-for="(item, index) in layout.header.navigation.items"
                :key="index"
                :href="item.link === 'module' ? `${baseUrl}/preview/application/${appId}/module/${item.moduleId}` : item.remoteUrl"
              >
                {{ item.label }}
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
        <div
          class="columns"
          style="margin-top: 50px"
        >
          <div
            v-if="layout.sidebar && layout.sidebar.show && layout.sidebar.position === 'left'"
            class="app-sidebar column is-3"
          >
            <b-menu>
              <img
                v-if="layout.sidebar.logo && layout.sidebar.logo.show"
                :src="layout.logo"
                style="max-width: 250px!important;"
                alt="Logo"
              >
              <b-menu-list>
                <b-menu-item
                  v-for="(item, index) in layout.sidebar.navigation.items"
                  :key="index"
                  :label="item.label"
                  tag="a"
                  :href="item.link === 'module' ? `${baseUrl}/preview/application/${appId}/module/${item.moduleId}` : item.remoteUrl"
                />
              </b-menu-list>
            </b-menu>
          </div>
          <div
            class="column"
            :class="layout.sidebar && layout.sidebar.show ? 'is-9' : 'is-12'"
          >
            <div
              v-if="loadingFinished"
              class="preview-web-content content"
            >
              <div
                style="padding-bottom: 50px !important;"
                :class="selectedTheme"
              >
                <div
                  v-if="response.sections"
                  class="content"
                >
                  <div
                    v-for="(section, sectionIndex) in response.sections"
                    :key="sectionIndex"
                  >
                    <form
                      v-if="section.show"
                      @input="update(false)"
                      @submit.prevent
                    >
                      <div
                        class="columns"
                      >
                        <div
                          v-for="(num, index) in section.fields"
                          :key="index"
                          class="column"
                          :class="section.columns === '1' ? 'is-12' : section.columns === '2' ? 'is-6' : 'is-4'"
                        >
                          <div
                            v-for="(field, fieldIndex) in section.fields[index]"
                            :key="fieldIndex"
                          >
                            <b-field
                              v-if="field.show && field.type !== 'hidden'"
                              class="column"
                              :class="field.properties.basic.width"
                              :type="typeof errors[field.id] !== 'undefined' || ibanError ? 'is-danger' : 'is-default'"
                              :message="errors[field.id] || (ibanError && field.type === 'iban') ? ibanError ? ibanError : errors[field.id] : ''"
                            >
                              <template
                                v-if="field.properties.basic.hasLabel"
                                #label
                              >
                                {{ field.properties.basic.label }} <span
                                  v-if="typeof field.properties.validation.isRequired !== 'undefined' && field.properties.validation.isRequired"
                                >*</span>
                                <b-tooltip
                                  v-if="field.properties.basic.hasInfoTooltip"
                                  type="is-dark"
                                  multilined
                                  position="is-right"
                                  size="is-large"
                                >
                                  <b-icon icon="help-circle-outline" />
                                  <template #content>
                                    {{ field.properties.basic.infoTooltipText }}
                                  </template>
                                </b-tooltip>
                              </template>

                              <editor
                                v-if="field.type === 'html-editor'"
                                v-model="values[field.id]"
                                :formula="false"
                              />

                              <b-input
                                v-if="field.type === 'text-input'"
                                v-model="values[field.id]"
                                size="is-small"
                                type="text"
                                :disabled="field.properties.basic.isDisabled"
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                :maxlength="field.properties.basic.hasLimit ? field.properties.basic.limit : ''"
                                @keyup.native.enter="goNext(false)"
                              />

                              <b-input
                                v-if="field.type === 'iban'"
                                v-model="values[field.id]"
                                size="is-small"
                                type="text"
                                :disabled="field.properties.basic.isDisabled"
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                @keyup.native.enter="goNext(false)"
                                @input="callDebounceIBAN($event)"
                              />
                              <b-input
                                v-if="field.type === 'email-input'"
                                v-model="values[field.id]"
                                type="email"
                                size="is-small"
                                :disabled="field.properties.basic.isDisabled"
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                @keyup.native.enter="goNext(false)"
                              />

                              <b-input
                                v-if="field.type === 'text-area'"
                                v-model="values[field.id]"
                                type="textarea"
                                size="is-small"
                                :disabled="field.properties.basic.isDisabled"
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                @keyup.native.enter="goNext(false)"
                              />
                              <BaseInput
                                v-if="field.type === 'number-input'"
                                v-model="values[field.id]"
                                :mask="{
                                  ...(field.properties.basic.currencySymbol || {}),
                                  ...(field.properties.basic.format || {})
                                }"
                                :type="field.properties.basic.currencySymbol || field.properties.basic.format ? 'text' : 'number'"
                                size="is-small"
                                :disabled="field.properties.basic.isDisabled"
                                :controls="field.properties.basic.showControls"
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                @keyup.native.enter="goNext(false)"
                              />

                              <div v-if="field.type === 'radio-box'">
                                <div :class="field.properties.basic.inOneLine ? 'block' : ''">
                                  <b-radio
                                    v-for="(choice, cIndex) in field.properties.basic.choices"
                                    :key="cIndex"
                                    v-model="values[field.id]"
                                    class="column is-12"
                                    size="is-small"
                                    :native-value="choice"
                                  >
                                    {{ choice }}
                                  </b-radio>
                                </div>
                              </div>

                              <div v-if="field.type === 'address'">
                                <div class="field has-addons mb-0">
                                  <p class="p-0 control column is-10">
                                    <b-autocomplete
                                      v-model="values['street_' + field.id]"
                                      :data="addresses"
                                      size="is-small"
                                      :custom-formatter="option => getStreet(option, field.id)"
                                      :placeholder="field.properties.basic.labels.street"
                                      @typing="callDebounceAddress($event, field)"
                                      @select="option => updateAddress(option, field.id)"
                                    >
                                      <template slot-scope="props">
                                        <p>{{ props.option.label }}</p>
                                      </template>
                                    </b-autocomplete>
                                  </p>
                                  <p class="p-0 control column is-2">
                                    <b-input
                                      v-model="values['number_' + field.id]"
                                      size="is-small"
                                      :placeholder="field.properties.basic.labels.number"
                                    />
                                  </p>
                                </div>
                                <div class="field has-addons mb-0">
                                  <p class="control p-0 column is-3">
                                    <b-input
                                      v-model="values['zipCode_' + field.id]"
                                      size="is-small"
                                      :disabled="false"
                                      :maxlength="5"
                                      :placeholder="field.properties.basic.labels.zipCode"
                                    />
                                  </p>
                                  <p class="control column p-0 mr-1 is-9">
                                    <b-input
                                      v-model="values['city_' + field.id]"
                                      size="is-small"
                                      :placeholder="field.properties.basic.labels.city"
                                    />
                                  </p>
                                </div>
                              </div>

                              <div
                                v-if="field.type === 'document'"
                                class="column is-12"
                              >
                                <iframe
                                  :src="field.properties.basic.isDynamic ? field.properties.basic.dynamicDocument.baseUrl + '/' + field.properties.basic.dynamicDocument.name + '.' + field.properties.basic.dynamicDocument.extension
                                    : field.properties.basic.document"
                                  frameBorder="0"
                                  scrolling="auto"
                                  height="1140px"
                                  width="1020px"
                                />
                              </div>

                              <div
                                v-if="field.type === 'iframe'"
                                class="column is-12"
                              >
                                <iframe
                                  :src="field.properties.basic.url"
                                  frameBorder="0"
                                  scrolling="auto"
                                  height="1140px"
                                  width="1020px"
                                />
                              </div>

                              <div
                                v-if="field.type === 'tariff-comparison'"
                                class="columns has-text-centered is-flex-wrap-wrap"
                              >
                                <h1
                                  v-for="(tariff, tIndex) in field.properties.basic.tariffs"
                                  :key="'title' + tIndex"
                                  class="title column is-4 mt-0 mb-1 px-2"
                                >
                                  {{ tariff.title }}
                                </h1>
                                <h2
                                  v-for="(tariff, tIndex) in field.properties.basic.tariffs"
                                  :key="'price' + tIndex"
                                  class="subtitle column is-4 mt-0 mb-2 px-2"
                                >
                                  {{ tariff.price }}
                                </h2>
                                <div
                                  v-for="(tariff, tIndex) in field.properties.basic.tariffs"
                                  :key="'description' + tIndex"
                                  class="column is-4 mt-0 mb-2 px-2 tariff-description"
                                  v-html="tariff.description"
                                />
                                <div
                                  v-for="(tariff, tIndex) in field.properties.basic.tariffs"
                                  :key="'select-tariff' + tIndex"
                                  class="column is-4 mt-0 mb-2 px-4"
                                >
                                  <b-button
                                    type="is-primary"
                                    expanded
                                    @click="selectTariff(field.id, tariff)"
                                  >
                                    {{ field.properties.basic.tariffSelectionButton.text || 'Select' }}
                                  </b-button>
                                </div>
                              </div>

                              <div
                                v-if="field.type === 'e-signature'"
                                class="column is-12"
                              >
                                <VueSignaturePad
                                  :id="field.id"
                                  ref="signature"
                                  :options="{ onBegin, onEnd }"
                                  width="340px"
                                  height="100px"
                                  style="border: 1px solid #dedede; margin-left: -10px;"
                                />
                                <b-field style="margin-left: -10px; max-width: 340px;">
                                  <b-button
                                    size="is-small"
                                    type="is-dark is-light is-outlined"
                                    expanded
                                    class="sign-btn"
                                    @click="clearSignature(field.id)"
                                  >
                                    <b-icon
                                      size="is-small"
                                      icon="cancel"
                                    />
                                    <span>Clear</span>
                                  </b-button>
                                </b-field>
                              </div>

                              <div v-if="field.type === 'checkbox'">
                                <b-checkbox
                                  v-for="(choice, cIndex) in field.properties.basic.choices"
                                  :key="cIndex"
                                  v-model="choices[field.id]"
                                  class="column is-12"
                                  size="is-small"
                                  :name="field.id"
                                  :true-value="choice"
                                  :native-value="choice"
                                  @input="addChoicesToModel(field.id, choice)"
                                >
                                  {{ choice }}
                                </b-checkbox>
                              </div>

                              <b-autocomplete
                                v-if="field.type === 'dropdown' && field.properties.basic.hasAutocomplete"
                                v-model="values[field.id]"
                                :data="filteredDataArray(field.properties.basic.choices, field.id)"
                                size="is-small"
                                expanded
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                @select="option => updateAutocomplete(option, field.id)"
                              />

                              <b-select
                                v-if="field.type === 'dropdown' && !field.properties.basic.hasAutocomplete"
                                v-model="selectChoices[field.id]"
                                size="is-small"
                                expanded
                                :multiple="field.properties.basic.multiselect"
                                :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
                                @input="addSelectChoicesToModel(field.id)"
                              >
                                <option
                                  v-for="(choice, cIndex) in field.properties.basic.choices"
                                  :key="cIndex"
                                >
                                  {{ choice }}
                                </option>
                              </b-select>

                              <DatePicker 
                                v-if="field.type === 'date'"
                                :field="field"
                                :values="values"
                                @update-date="changeDate"
                              />

                              <PaymentGateway 
                                v-if="field.type === 'payment-gateway'"
                                :field="field"
                                :values="values"
                                @update-payment-status="updatePaymentStatus(field.id, $event)"
                              />

                              <div v-if="field.type === 'file-upload'">
                                <b-upload
                                  v-model="file[field.id]"
                                  size="is-small"
                                  expanded
                                  @input="setFile(field.id); upload(field.id)"
                                >
                                  <span class="file-cta">
                                    <b-icon
                                      class="file-icon"
                                      size="is-small"
                                      icon="upload"
                                    />
                                    <span class="file-label">{{ field.properties.basic.label }}</span>
                                  </span>
                                  <span
                                    v-if="file[field.id]"
                                    class="file-name"
                                    style="border: none!important;"
                                  >
                                    {{ file[field.id].name }}
                                  </span>
                                </b-upload>
                              </div>

                              <b-input
                                v-if="field.type === 'password'"
                                v-model="values[field.id]"
                                size="is-small"
                                type="password"
                                :disabled="field.properties.basic.isDisabled"
                                :password-reveal="field.properties.basic.buttonToShowPassword"
                                placeholder="*******"
                                @keyup.native.enter="goNext(false)"
                              />

                              <div
                                v-if="field.type === 'text'"
                                v-html="field.properties.basic.text"
                              />

                              <div
                                v-if="field.type === 'data-table'"
                                style="max-width: 100%;"
                              >
                                <b-field 
                                  v-if="field.properties.basic.tableIsExportable"
                                  grouped
                                  group-multiline
                                >
                                  <div class="control">
                                    <b-button
                                      type="is-light"
                                      size="is-small"
                                      icon-pack="mdi"
                                      icon-left="file-excel-outline"
                                      @click="exportAsCsv(sectionIndex, index, fieldIndex)"
                                    >
                                      Export
                                    </b-button>
                                  </div>
                                </b-field>

                                <b-table
                                  ref="data-table"
                                  :data="field.properties.basic.data"
                                  :paginated="field.properties.basic.pagination > 0"
                                  :per-page="field.properties.basic.pagination"
                                  pagination-size="is-small"
                                  @click="goNext"
                                >
                                  <template v-for="column in field.properties.basic.headers">
                                    <b-table-column
                                      :key="column.id"
                                      v-bind="column"
                                      :sortable="field.properties.basic.tableIsSortable"
                                      :searchable="field.properties.basic.tableIsFilterable"
                                      width="100"
                                    >
                                      <template #default="props">
                                        {{ props.row[column.field] }}
                                      </template>
                                    </b-table-column>
                                  </template>
                                </b-table>
                              </div>

                              <div v-if="field.type === 'toc'">
                                <b-checkbox
                                  :key="index"
                                  v-model="toc"
                                  class="column is-12"
                                  size="is-small"
                                  :native-value="field.id"
                                  @input="addTocAcceptance(field.id)"
                                >
                                  <p v-html="field.properties.basic.text" />
                                </b-checkbox>
                              </div>

                              <b-button
                                v-if="field.type === 'next-btn'"
                                class="is-black is-dark"
                                :expanded="field.properties.basic.isBlock"
                                @click="goNext(false, field.properties.basic.clearStateOnSuccess)"
                              >
                                {{ field.properties.basic.btnText }}
                              </b-button>
                              <b-button
                                v-if="field.type === 'back-btn'"
                                class="is-dark is-outlined"
                                is-light
                                :expanded="field.properties.basic.isBlock"
                                @click="goBack(field.properties.basic.clearStateOnSuccess)"
                              >
                                {{ field.properties.basic.btnText }}
                              </b-button>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              style="max-width: 500px"
            >
              <img
                style="width: 64px!important;"
                :src="require('../../../assets/spinner.gif')"
                class="spinner"
                alt="Loading"
              >
            </div>
          </div>
          <div
            v-if="layout.sidebar && layout.sidebar.show && layout.sidebar.position === 'right'"
            class="app-sidebar column is-3"
          >
            <b-menu>
              <img
                v-if="layout.sidebar.logo.show"
                :src="layout.logo"
                style="max-width: 250px!important;"
                alt="Logo"
              >
              <b-menu-list>
                <b-menu-item
                  v-for="(item, index) in layout.sidebar.navigation.items"
                  :key="index"
                  :label="item.label"
                  tag="a"
                  :href="item.link === 'module' ? `${baseUrl}/preview/application/${appId}/module/${item.moduleId}` : item.remoteUrl"
                />
              </b-menu-list>
            </b-menu>
          </div>
        </div>
        <div
          v-if="layout.footer && layout.footer.show"
          class="app-footer"
        >
          <div
            v-if="layout.footer.copyRight.show"
            class="has-text-centered"
          >
            <p class="is-size-7">
              {{ layout.footer.copyRight.text }}
            </p>
          </div>
          <div class="columns">
            <div class="column">
              <img
                v-if="layout.footer.logo.show"
                :src="layout.logo"
                style="max-width: 250px!important;"
                alt="Logo"
              >
              <b-menu v-else-if="layout.footer.navigation.show && !layout.footer.logo.show">
                <b-menu-list>
                  <b-menu-item
                    v-for="(item, index) in layout.footer.navigation.items"
                    :key="index"
                    :label="item.label"
                    tag="a"
                    :href="item.link === 'module' ? `${baseUrl}/preview/application/${appId}/module/${item.moduleId}` : item.remoteUrl"
                  />
                </b-menu-list>
              </b-menu>
            </div>
            <div class="column">
              <b-menu v-if="layout.footer.navigation.show && layout.footer.logo.show">
                <b-menu-list>
                  <b-menu-item
                    v-for="(item, index) in layout.footer.navigation.items"
                    :key="index"
                    :label="item.label"
                    tag="a"
                    :href="item.link === 'module' ? `${baseUrl}/preview/application/${appId}/module/${item.moduleId}` : item.remoteUrl"
                  />
                </b-menu-list>
              </b-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-collapse
      v-if="consoleMessages.length"
      animation="slide"
      class="mb-2 box"
      style="position: fixed; bottom: 0; width: 100%; z-index: 999; max-height: 300px; overflow-y: scroll"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          style="background: #FFFFFF"
        >
          <p
            class="card-header-title"
            style="background: #FFFFFF; font-weight: normal"
          >
            Console
          </p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
          </a>
        </div>
      </template>
      <div class="p-2">
        <table
          class="table is-striped"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th style="width: 200px !important;">
                <small><strong>Action</strong></small>
              </th>
              <th style="width: 200px !important;">
                <small><strong>Message</strong></small>
              </th>
              <th><small><strong>Data</strong></small></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(message, index) in consoleMessages"
              :key="index"
            >
              <td><small>{{ message.data.action }}</small></td>
              <td><small>{{ message.data.message }}</small></td>
              <td>
                <pre><small>{{ message.data.meta }}</small></pre>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-collapse>
  </div>
</template>

<script>

import axios from 'axios';
import xlsx from 'json-as-xlsx';
import moment from 'moment-timezone';
import lodash from 'lodash';
import {runModuleService} from '@/services/interpreter-service/interpreterModuleRequests';
import cleave from '@/directives/cleave';
import {defineAsyncComponent} from '@vue/composition-api';
import FormRenderer from '@/helpers/FormRenderer.js';
import Editor from '@/modules/core/components/wysiwyg/Editor';
import { DatePicker, PaymentGateway } from '@/modules/preview/components/componentsIndex';


export default {
    components: {
        BaseInput: defineAsyncComponent(() => import('@/modules/core/components/generics/BaseInput.vue')),
        Editor,
        DatePicker,
        PaymentGateway
    },
    directives: {
        cleave
    },
    data: () => ({
        response: Object,
        docsContentType: 'application/pdf, plain/text, plain/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingm',
        imageContentType: 'image/png, image/jpg, image/jpeg',
        currentNodeId: 2,
        previousNodeId: null,
        containerClasses: '',
        session: null,
        values: {},
        file: {},
        contents: {},
        consoleMessages: [],
        loadingFinished: false,
        choices: {},
        toc: [],
        theme: 'Default',
        errors: {},
        date: new Date(),
        firstLoad: true,
        selectChoices: {},
        modelChoices: {},
        layout: {},
        autoCompleteSelected: null,
        baseUrl: '',
        appId: null,
        tariff: {},
        sign: {
            option:{
                penColor:'rgb(0, 0, 0)',
                backgroundColor:'rgb(255,255,255)'
            },
            dataUrl:''
        },
        signatureType: 'pen',
        addresses: [],
        ibanError: false,
    }),
    computed: {
        selectedTheme() {
            let selectedTheme;
            switch (this.theme) {
            case 'Purple':
                selectedTheme = 'purple';
                break;
            case 'Dark Blue':
                selectedTheme = 'dark-blue';
                break;
            default:
                selectedTheme = 'default';
                break;
            }
            return selectedTheme;
        }
    },
    mounted() {
        this.baseUrl = window.env.VUE_APP_DOMAIN;
        this.appId = this.$route.params.appId;
        this.runModule(true);
    },
    methods: {
        filteredDataArray(array, id) {
            return array.filter((option) => {
                if (this.values[id]) {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.values[id].toLowerCase()) >= 0;
                }
                return option;
            });
        },
        async checkAddress(e, field) {
            const zipCode = this.values['zipCode_' + field.id];
            const response = await axios.get(`${window.env.VUE_APP_APP_SERVICE_DOMAIN}/address?zipCode=${zipCode}&address=${e}`);
            this.addresses = response.data;
        },
        async checkIBAN(e) {
            const response = await axios.get(`${window.env.VUE_APP_APP_SERVICE_DOMAIN}/iban?iban=${e}`);
            if (!response.data.is_valid) {
                this.ibanError = 'Bitte geben Sie eine gültige IBAN an';
            } else {
                this.ibanError = false;
            }
        },
        async checkAddressGeoLocation(lat, long, id) {
            const response = await axios.get(`${window.env.VUE_APP_APP_SERVICE_DOMAIN}/geo?latitude=${lat}&longitude=${long}`);
            this.addresses = response.data;
            this.updateAddress(this.addresses[0], id);
        },
        callDebounceAddress: lodash.debounce(async function (e, field) {
            await this.checkAddress(e, field);
        }, 500),
        callDebounceIBAN: lodash.debounce(async function (e) {
            console.log(this);
            await this.checkIBAN(e);
        }, 500),
        updateAddress(option, id) {
            this.values['street_' + id] = option.address.street;
            this.values['zipCode_' + id] = option.address.postalCode;
            this.values['city_' + id] = option.address.city;
            this.values['country_' + id] = option.address.country;
            this.update(false);
        },
        getStreet(option, id) {
            return this.values['street_' + id];
        },
        updateAutocomplete(option, id) {
            this.$set(this.values, id, option);
            this.update();
        },
        saveSign(id) {
            const png = this.$refs.signature[0].saveSignature();
            this.$set(this.values, id, png.data);
        },
        clearSignature(id) {
            const png = this.$refs.signature[0].clearSignature();
            this.$set(this.values, id, '');
            this.update();
        },
        onBegin() {
            // do nothing
        },
        onEnd(e) {
            this.saveSign(this.$refs.signature[0].$attrs.id);
        },
        async runModule (onLoad = false) {
            try {
                const { query } = this.$route;
                const { data: { data: moduleData } } = await runModuleService(
                    this.$route.params.moduleId,
                    '',
                    {
                        preview: true
                    },
                    query
                );
                this.currentNodeId = moduleData.current_id;
                this.previousNodeId = moduleData.previous_id;
                this.session = moduleData.session;
                if (moduleData.theme === 'Dark purple') {
                    this.theme = 'Purple';
                }
                this.values = moduleData.values;
                moduleData.response.forEach(response => {
                    if (response.status === 1203) {
                        window.location.href = response.data.url;
                    } else if (response.status === 1005 ) {
                        this.firstLoad = false;
                        this.loadingFinished = true;
                        this.parseLayout(response.data.layout_data);
                        response.data.sections.forEach(section => {
                            section.fields.forEach(columns => {
                                columns.forEach(field => {
                                    console.log(field.type);
                                    if (field.type === 'date') {
                                        field.value = moment(field.value).toDate();
                                    }
                                    if (field.type === 'checkbox') {
                                        if (field.value) {
                                            this.choices[field.id] = field.value.split(',');
                                        } else {
                                            this.choices[field.id] = [];
                                        }
                                    }
                                    if (field.type === 'dropdown') {
                                        if (field.value) {
                                            if (field.properties?.basic.multiselect) {
                                                this.selectChoices[field.id] = field.value.split(',');
                                            } else {
                                                this.selectChoices[field.id] = field.value;
                                            }
                                        } else {
                                            this.selectChoices[field.id] = [];
                                        }
                                    }
                                });
                            });
                        });
                        this.response = response.data;
                        this.update(onLoad);
                    }
                });
            } catch (err) {
                console.error(err);
            }
        },

        parseLayout(data) {
            if (data) {
                this.layout = data;
            } else {
                this.layout = {};
            }
        },

        upload(id) {

            let formData = new FormData();
            formData.append('file', this.file[id]);

            // ignore replacing with services for now
            axios.post(window.env.VUE_APP_APP_SERVICE_DOMAIN + '/upload', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            });
        },
        setFile(id) {
            this.values[id] = this.file[id].name;
        },
        changeDate(payload) {
            const updatedDate = payload.updatedDate ? moment(payload.updatedDate).toDate() : null;
            if (this.values[payload.id] === undefined) {
                this.$set(this.values, payload.id, updatedDate);
            } else {
                this.values[payload.id] = updatedDate;
            }
            this.update(false);
        },
        updatePaymentStatus (fieldId, newStatus) {
            if (this.values[fieldId] === undefined) {
                this.$set(this.values, fieldId, newStatus);
            } else {
                this.values[fieldId] = newStatus;
            }
            this.update(false);
        },
        addChoicesToModel(domId, choice) {
            if (this.values[domId]) {
                this.choices[domId] = this.values[domId].split(',');
            } else {
                this.choices[domId] = [];
            }
            const index = this.choices[domId].indexOf(choice);
            if (index !== -1) {
                this.choices[domId].splice(index, 1);
            } else {
                this.choices[domId].push(choice);
            }
            this.values[domId] = this.choices[domId].join(',');
        },
        addSelectChoicesToModel(domId) {
            if (Array.isArray(this.selectChoices[domId])) {
                this.values[domId] = this.selectChoices[domId].join(',');
            } else {
                this.values[domId] = this.selectChoices[domId];
            }
        },
        addTocAcceptance(domId) {
            this.values[domId] = this.toc;
        },
        showMobile(show) {
            if (show) {
                this.containerClasses = 'mobile-container';
            } else {
                this.containerClasses = '';
            }
        },
        parseValues(onLoad = false) {
            this.response.sections.forEach(section => {
                section.fields.forEach(columns => {
                    columns.forEach(field => {
                        if (field.type === 'date') {
                            field.value = moment(field.value).toDate();
                        } else if (field.type === 'e-signature') {
                            setTimeout( () => {
                                if (this.$refs.signature[0]) {
                                    this.$refs.signature[0].fromDataURL(field.value);
                                }
                            }, 500);
                        } else if (field.type !== 'data-table' && field.type !== 'hidden') {
                            if (field.value && onLoad) {
                                this.$set(this.values, field.id, field.value);
                            }
                        }
                    });
                });
            });
            this.firstLoad = false;
        },
        parseActionsValue(values) {
            for (const key in values ) {
                this.values[key] = values[key];
            }
        },
        getAccepted(allowed) {
            let accepted = '';
            if (allowed.includes('docs')) {
                accepted += this.docsContentType + ' ';
            }
            if (allowed.includes('images')) {
                accepted += this.imageContentType + ' ';
            }
            return accepted;
        },
        update(onLoad = false) {
            this.parseValues(onLoad);
            setTimeout(() => {
                this.response = FormRenderer.check(this.response, this.values);
            }, 100);
        },
        openErrorToast() {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'There was an unexpected error. Please try again later.',
                position: 'is-top',
                type: 'is-danger'
            });
        },
        selectTariff(id, tariff) {
            this.values['title_' + id] = tariff.title;
            this.values['premium_' + id] = tariff.price;
            this.values['description_' + id] = tariff.description;
            this.goNext();
        },
        async goNext(row = null, reload = true) {
            if (this.ibanError) {
                return false;
            }
            try {
                let url = new URL(window.location.href);
                if (row) {
                    if (url.search === '') {
                        url.search = 'id=' + row.ID;
                        window.history.pushState(null, null, '?id=' + row.ID);
                    } else {
                        url.search += '&id=' + row.ID;
                        window.history.pushState(null, null, '&id=' + row.ID);
                    }
                }
                this.errors = {};
                this.loadingFinished = false;
                this.firstLoad = reload;
                console.log(this.currentNodeId);
                const { data: { data: moduleData } } = await runModuleService(this.$route.params.moduleId, `${url.search}`,{
                    values: this.values,
                    data: this.response,
                    node_id: this.currentNodeId,
                    session: this.session,
                    preview: true
                });
                this.currentNodeId = moduleData.current_id;
                this.previousNodeId = moduleData.previous_id;
                this.session = moduleData.session;
                this.parseActionsValue(moduleData.values);
                moduleData.response.forEach(res => {
                    if (res.status === 1203) {
                        window.location.href = res.data.url;
                    } else if (res.status === 1005) {
                        this.parseLayout(res.data.layout_data);
                        this.response = res.data;
                        this.response.sections.forEach(section => {
                            section.fields.forEach(columns => {
                                columns.forEach(field => {
                                    if (field.type === 'checkbox') {
                                        const selectedValue = this.values[field.id] || field.value;
                                        this.choices[field.id] = [];
                                        if (selectedValue) {
                                            this.choices[field.id].push(...selectedValue.split(','));
                                        }
                                    }
                                    if (field.type === 'dropdown') {
                                        const selectedValue = this.values[field.id] || field.value;
                                        this.selectChoices[field.id] = [];
                                        if (selectedValue) {
                                            if (field.properties?.basic.multiselect) {
                                                this.selectChoices[field.id].push(...selectedValue.split(','));
                                            } else {
                                                this.selectChoices[field.id] = selectedValue;
                                            }
                                        }
                                    }
                                });
                            });
                        });
                        this.update(true);
                    }  else {
                        this.consoleMessages.push({
                            data: res.data,
                        });
                    }
                });
                this.loadingFinished = true;
            } catch (err) {
                if (err.response.data.errors) {
                    for (let err of err.response.data.errors) {
                        err.message.forEach(message => {
                            this.$set(this.errors, err.id, message);
                        });
                    }
                } else {
                    this.openErrorToast();
                }
                this.loadingFinished = true;
            }
        },
        async goBack(reload) {
            try {
                let url = new URL(window.location.href);
                let params = new URLSearchParams(url.search);
                this.errors = {};
                this.firstLoad = reload;
                this.loadingFinished = false;
                const { data: { data: moduleData } } = await runModuleService(this.$route.params.moduleId, url.search,{
                    values: this.values,
                    data: this.response,
                    node_id: this.previousNodeId,
                    session: this.session,
                    back: true,
                    preview: true
                });

                this.currentNodeId = moduleData.current_id;
                this.previousNodeId = moduleData.previous_id;
                this.session = moduleData.session;
                moduleData.response.forEach(res => {
                    if (res.status === 1005) {
                        this.parseLayout(res.data.layout_data);
                        this.response = res.data;
                        this.update(false);
                    }
                });
                this.loadingFinished = true;
            } catch (err) {
                console.error(err);
            }
        },
        backToBuilder() {
            this.$router.push({path: '/application/' + this.$route.params.appId + '/module/' + this.$route.params.moduleId + '/edit'});
        },
        exportAsCsv(sectionIndex, index, fieldIndex) {
            const columns = this.response.sections[sectionIndex].fields[index][fieldIndex].properties.basic.headers.map((header) => {
                const obj = Object.create(null);
                obj.label = header.label;
                obj.value = header.field;
                return obj;
            });

            const content = this.$refs['data-table'][0].newData;

            let data = [
                {
                    sheet: 'Data Table',
                    columns,
                    content,
                },
            ];

            let settings = {
                fileName: 'Data-Spreadsheet',
                extraLength: 3,
                writeOptions: {},
            };

            xlsx(data, settings);
        }
    }
};
</script>

<style lang="scss">
//@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap');

@font-face {
  font-family: "MetaSans Medium";
  src: url('https://www.gothaer.de/style/fonts/MetaW05-Medium.woff2') format('woff2');
}

@font-face {
  font-family: "MetaSans Regular";
  src: url('https://www.gothaer.de/style/fonts/MetaW05-Regular.woff2') format('woff2');
}

.preview {

  .tariff-description {
    * {
      text-align: left;
    }
  }

  .block label {
    width: auto!important;
  }

  .preview-web {
    width: 100%;
    height: 100%;
    background: #ededed;
    margin-top: -100px;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .preview-frame {
    min-width: 1600px;
    width: 80%;
    margin: 0 auto;
    background: #ffffff;
    min-height: 800px;
  }

  .preview-frame-arrows {
    float: left;
  }

  .preview-frame-address-bar {
    min-width: 950px;
    width: calc(100% - 250px);
    height: 26px;
    padding: 0 10px;
    background: linear-gradient(90deg, #ededed 25%, hsla(0, 0%, 86%, .5) 50%, #ededed 75%);
    border-radius: 10px;
    float: left;
    margin-left: 20px;
  }

  .preview-frame-header {
    min-width: 1000px;
    width: 100%;
  }

  .preview-frame-address-bar span {
    font-size: 12px;
  }

  .preview-frame-buttons {
    float: left;
    margin-left: 50px;
  }

  .preview-frame-buttons div {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(90deg, #ededed 25%, hsla(0, 0%, 86%, .5) 50%, #ededed 75%);
    margin-right: 12px;
    float: left;
  }

  .preview-web-content {
    width: 1000px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .mobile-container {
    max-width: 550px !important;
  }

  .spinner {
    position: absolute;
    top: 20%;
    left: calc(50% - 50px);
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .custom-image-small {
    max-width: 200px;
  }

  .custom-image-medium {
    max-width: 500px;
  }

  .custom-image-large {
    max-width: 100%;
  }

  .custom-image-float-none {
    float: none;
  }

  .custom-image-float-left {
    float: left;
  }

  .custom-image-float-right {
    float: right;
  }

  ul {
    padding-left: 20px !important;
  }

  .preview-web p {
    min-height: 1rem !important;
  }

  .purple {
    font-family: 'Yanone Kaffeesatz', sans-serif !important;
    font-size: 1.2rem !important;

    .navbar {
      background: #8F0B5D !important;
      color: white !important;
    }

    .btn-secondary {
      background: #870861 !important;
    }

    .btn-dark {
      background: #8F0B5D !important;
    }
  }

  h1 {
    font-weight: bolder;
    font-size: 2rem;
  }

  h2 {
    font-weight: bold;
    font-size: 1.6rem;
  }

  h3 {
    font-weight: normal;
    font-size: 1.3rem;
  }

  blockquote {
    display: block;
    padding-left: 1rem;
    margin: 1rem 0;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  ul {
    list-style-type: disc;
  }

  .var-img {
    width: 250px!important;
    height: 85px!important;
    margin: 0!important;
  }

  .menu-list {
    list-style: none;
    padding-left: 0!important;
  }

  ol {
    padding-left: 20px !important;
  }

  .dark-blue {
    font-family: "MetaSans Regular", Roboto, sans-serif;
    width: 800px;
    margin: 0 auto;

    .label {
      font-size: 1.3rem;
    }

    .control-label {
      font-size: 1.1rem;
    }

    .b-radio.radio input[type=radio] + .check {
      width: 32px;
      height: 32px;
    }

    .b-radio.radio input[type=radio]:checked + .check {
      border: 1px solid #00718f;
      border-radius: 50%;
      background-color: transparent;
    }

    .b-radio.radio input[type=radio] + .check:before {
      background-color: #00718f;
      top: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      width: 30px;
      height: 30px;
    }

    .b-radio.radio:hover input[type=radio]:not(:disabled) + .check {
      border-color: #00718f;
    }

    .b-checkbox.checkbox input[type=checkbox] + .check {
      width: 32px;
      height: 32px;
    }

    .b-checkbox.checkbox input[type=checkbox]:checked + .check {
      border: 1px solid #00718f;
      background-color: #00718f;
    }

    .b-checkbox.checkbox input[type=checkbox] + .check:before {
      background-color: #00718f;
    }

    .b-checkbox.checkbox:hover input[type=checkbox]:not(:disabled) + .check {
      border-color: #00718f;
    }

    .select select, .taginput .taginput-container.is-focusable, .textarea, .input {
      padding-top: 19px;
      padding-bottom: 19px;
      font-size: 20px;
      line-height: 26px;
      border: none;
      border-bottom: 1px solid #00718f;
      background-color: rgba(229,240,243,.5);
    }

    .button {
      border-radius: 45px;
      height: 90px;
      width: 90px;
      bottom: 0;
    }

    .button.is-dark {
      float: left;
      background-color: #fff;
      border: 1px solid #00718f;
      color: #00718f;
    }

    .button.is-dark:hover {
      color: #fff;
      background-color: #00718f;
      opacity: 0.5;
    }

    .button.is-black {
      float: right;
      background-color: #00718f;
      border: 1px solid #00718f;
      color: #fff;
    }

    .button.is-black:hover {
      opacity: 0.5;
      color: #fff;
    }

    .select select {
      padding: 0 10px;
    }

    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: #00718f;
      right: 2.125em;
    }

    .navbar-link:not(.is-arrowless)::after, .select:not(.is-multiple):not(.is-loading)::after {
      height: 1rem;
      width: 1rem;
      top: 60%;
    }

    p {
      font-size: 20px;
    }

    h3 {
      font-size: 24px;
    }

    h2 {
      font-size: 28px;
    }

    h1 {
      font-size: 32px;
    }
  }
}
</style>
