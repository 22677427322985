import VariableParser from '@/helpers/VariableParser';
const FormulaParser = require('hot-formula-parser').Parser;
import FP from './../FormulaParser';

export default new class ArithmeticCalculation {
    constructor() {
        this.formula_parser = new FormulaParser();
    }

    parse(data, values) {
        const text = FP.parse(data.data.formula, values);
        let formula = VariableParser.parse(text, values).replace(/(<([^>]+)>)/gi, '').trim();
        let result = this.formula_parser.parse(formula);
        if (!result.error) {
            return result.result;
        }
        return '';
    }
};