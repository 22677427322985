<template>
  <b-datepicker
    v-bind="dateRange"
    :key="'datepicker-' + renderKey"
    :value="date"
    size="is-small"
    expanded
    editable
    locale="de-DE"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    data-unselectable-dates="getRestrictedDates"
    @input="changeDate(field.id, $event)"
  />
</template>

<script >
import { computed, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const renderKey = ref(0);
  const date = computed(() => {
    if (props.values[props.field.id]) {
      return typeof props.values[props.field.id] === 'string' ? new Date(props.values[props.field.id]) : props.values[props.field.id];
    }
    return null;
  });
  const dateRange = computed(() => {
    const {
      properties: {
        validation: {
          isRestrictedFromDynamic,
          restrictedFrom,
          restrictedTo,
          isRestrictedToDynamic
        }
      }
    } = props.field;
    let restrictedFromDate = isRestrictedFromDynamic ? props.values[restrictedFrom.reference] : restrictedFrom;
    if (restrictedFromDate && typeof restrictedFromDate === 'string') {
      restrictedFromDate = new Date(restrictedFromDate);
    }
    let restrictedToDate = null;
    if (restrictedTo) {
      restrictedToDate = isRestrictedToDynamic ? props.values[restrictedTo.reference] : restrictedTo;
      if (restrictedToDate && typeof restrictedToDate === 'string') {
        restrictedToDate = new Date(restrictedToDate);
      }
    }
    return {
      ...(restrictedFromDate ? {
        minDate: restrictedFromDate
      } : {}),
      ...(restrictedToDate ? {
        maxDate: restrictedToDate
      } : {})
    };
  });
  const changeDate = (id, updatedDate) => {
    const {
      minDate,
      maxDate
    } = dateRange.value;
    // validate date range since buefy date picker doesn't do it for dates directly typed through input
    const isDateInvalid = minDate && updatedDate.getTime() < minDate.getTime() || maxDate && updatedDate.getTime() > maxDate.getTime();
    if (isDateInvalid) {
      updatedDate = null;
    }
    emit('update-date', {
      id,
      updatedDate
    });
    // force render date picker as input inside buefy's datepicker have a stale state issue (only happens in case of directly typing date in input)
    renderKey.value++;
  };
  return {
    renderKey,
    date,
    dateRange,
    changeDate
  };
};
export default __sfc_main;
</script>
