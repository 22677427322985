import { parseExpression } from '@/helpers/ExpressionParser';

export default new class  Concatenate {

    parse(data, values) {
        let parsedString = '';
        for (const str of data.data.instances) {
            parsedString += parseExpression(str.value, values);
            parsedString +=  this.parseGlue(data.data.glue, data.data.customSeparator, values);
        }
        return parsedString.slice(0, -1);
    }

    parseGlue(value, custom, values) {
        switch (value) {
        case 'Space':
            return ' ';
        case 'Comma':
            return ',';
        case 'Custom':
            return parseExpression(custom, values);
        }
    }
};

