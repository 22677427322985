import VariableParser from '@/helpers/VariableParser';
const moment = require('moment-timezone');
import FP from './../FormulaParser';

export default new class  TransformTimezone {

    parse(data, values) {
        const text = FP.parse(data.data.date.value, values);
        const value = VariableParser.parse(text, values)
            .replace(/(<([^>]+)>)/gi, '').trim();
        const first = moment.tz(value, data.data.date.fromTz).format();
        if (data.data.date.format === 'Custom') {
            const customFormatText = FP.parse(data.data.date.customFormat, values);
            const customFormat =  VariableParser.parse(customFormatText, values)
                .replace(/(<([^>]+)>)/gi, '').trim();
            return moment.tz(first, data.data.date.toTz).format(customFormat);
        }

        return moment.tz(first, data.data.date.toTz).format(data.data.date.format);
    }
};

