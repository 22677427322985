import VariableParser from '@/helpers/VariableParser';
import FP from './../FormulaParser';

export default new class  GenerateFileLink {

    parse(data, values) {
        const fileName = FP.parse(data.data.fileName, values);
        return `${window.env.VUE_APP_APP_SERVICE_DOMAIN}/${VariableParser.parse(fileName, values).replace(/(<([^>]+)>)/gi, '').trim()}`;
    }
    
};

